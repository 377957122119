import * as Sentry from '@sentry/react';
import history from 'router/history';

const setupSentry = (config: DodocConfig) => {
  const release = import.meta.env.VITE_APP_UI_VERSION;

  // if (release !== 'develop') {
  // Sentry
  //! Sentry should probably be initialized earlier, before other imports
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({ console: false }),
    ],
    normalizeDepth: 50, // Or however deep you want your state context to be.
    normalizeMaxBreadth: 500,

    ...config.sentry.config,
  });
  // }
};

export default setupSentry;
